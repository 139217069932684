<template>
  <v-container fluid class="px-6">
    <slides v-if="!isFeature" class="my-10" />
    <home-page-feature v-else />
    <!-- <v-row justify="center" no-gutters>
      <v-col cols="12" align="center" align-self="center">
        <div class="pa-2 font-weight-bold text-h5">Our Apps are now available on the Google Play Store and Apple App Store!</div>

        <v-btn x-large href="https://app.example.com" elevation="8" tag="anchor" rel="noFollow" target="_blank" class="font-weight-bold" color="primary"> Download Our Apps!</v-btn>
      </v-col>
    </v-row> -->

    <v-row class="my-8">
      <v-col cols="12" md="4">
        <home-page-tile
          route="Message"
          :image="require('@/assets/sermon.jpg')"
          text="Featured Sermon"
        />
      </v-col>

      <v-col cols="12" md="4">
        <todays-verse @activate="activateVerse(dayOfYear)" @close="closeVerse" />
      </v-col>
      <v-col cols="12" md="4">
        <home-page-tile route="Blogs" :image="require('@/assets/blog.jpg')" text="Blog Page" />
      </v-col>
    </v-row>
    <home-page-testimony @goto="goto" />
    <wallpaper-download />
    <home-page-about />
    <home-page-fab />
    <v-sheet height="100" style="background: transparent" />
    <video-sheet />
  </v-container>
</template>

<script>
import Slides from "@/components/Slides.vue";
import HomePageAbout from "@/components/homePage/HomePageAbout.vue";
import HomePageFab from "@/components/homePage/HomePageFabLinks.vue";
import HomePageFeature from "@/components/homePage/HomePageFeature.vue";
import HomePageTestimony from "@/components/homePage/HomePageTestimony.vue";
import HomePageTile from "@/components/homePage/HomePageTile.vue";
import VideoSheet from "@/components/homePage/VideoSheet.vue";
import TodaysVerse from "@/components/verseOfTheDay/TodaysVerse.vue";
import WallpaperDownload from "../components/WallpaperDownload.vue";

export default {
  name: "Home",
  data: () => ({}),
  components: {
    WallpaperDownload,
    Slides,
    TodaysVerse,
    VideoSheet,
    HomePageTestimony,
    HomePageTile,
    HomePageAbout,
    HomePageFab,
    HomePageFeature,
  },
  computed: {
    dayOfYear() {
      return this.$store.getters["verse/dayOfYear"];
    },
    blogImage() {
      return this.$store.state.mostRecentBlog.picture;
    },
    isFeature() {
      return (
        this.$store.state.featureObjects.features &&
        this.$store.state.featureObjects.features.content
      );
    },
  },
  methods: {
    goto() {
      this.$router.push({
        name: "Testimonies",
        params: { query: "testimony-form" },
      });
    },
    closeVerse() {
      this.$router.push({ name: "Home" });
    },
    activateVerse() {
      this.$router.push({ name: "Verse", params: { verseId: this.dayOfYear } });
    },
  },
  mounted() {
    this.$store.dispatch("slideObjects/getSlides");
  },
  metaInfo() {
    return {
      title: "GraceWay Radio",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Pure Worship; Pure Word; Pure Radio",
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: "Pure Worship; Pure Word; Pure Radio",
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "GraceWay Radio - Pure Worship; Pure Word; Pure Radio",
        },
        { vmid: "og:url", property: "og:url", content: "https://gracewayradio.com" },
        {
          property: "og:image",
          content: "https://gracewayradio.com/artwork/customMissing.jpg",
        },
        { property: "og:site_name", content: "GraceWay Radio" },
        {
          property: "og:video",
          content: "https://d1cku70i8cl0rj.cloudfront.net/GracewayPromowCreditsWEBM.webm",
        },
        {
          property: "og:video:secure_url",
          content: "https://d1cku70i8cl0rj.cloudfront.net/GracewayPromowCreditsWEBM.webm",
        },
        { property: "og:video:type", content: "video/webm" },
        { property: "og:type", content: "website" },
        { vmid: "robots", name: "robots", content: "index,follow" },
      ],
    };
  },
};
</script>

<style>
.hover-text {
  font-size: 2.5vw;
  font-weight: 900;
  color: white;
  padding: 0.1em 0.5em 0.2em;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
