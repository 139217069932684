<template>
  <v-container fluid>
    <v-row justify="center" align-content="center">
      <v-col md="6">
        <v-img v-if="wallpaper" :src="wallpaper.image" :alt="wallpaper.alt" contain max-height="300px" />
        <v-img v-else src="https://placehold.co/300" alt="this" contain max-height="300px" />
      </v-col>
      <v-col class='text-center' md="6">
        <v-card elevation="10">
          <v-card-title style="word-break:break-word;">Need inspiration to memorized more scripture this year? We can
            help!</v-card-title>
          <v-card-text>
            <p class="text--primary text-left font-weight-medium">Each month we'll create beautiful wallpaper with a
              different portion of
              Scripture
              you can
              download and place as your desktop on your device. That way the scripture will "ever be before your eyes"
              or
              at least while you're on your computer and each time you see it, practice memorizing a portion of it.
              Then,
              next month, download another portion to memorized. By the end of the year, you'll have entire chapters
              memorized! It's never too late to start, so download today and start memorizing!
            </p>
            <em>Download button will open a new window. Right click image, then select "Save image as"</em>
          </v-card-text>
          <v-card-actions>

            <v-btn @click="download()" color="primary" class="mx-auto">Download</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
Each month we'll create beautiful wallpaper with a different portion of Scripture you can download and place as your desktop on your device. That way the scripture will "ever be before your eyes" or at least while you're on your computer and each time you see it, practice memorizing a portion of it. Then, next month, download another portion to memorized. By the end of the year, you'll have entire chapters memorized! It's never too late to start, so download today and start memorizing!

<script>
import axios from 'axios';
export default {
  data: () => ({
    wallpaper: undefined,
  }),
  methods: {
    getWallpaper() {
      axios.get('https://gwrapi.herokuapp.com/wallpapers').then((res) => {
        if (res && Array.isArray(res.data)) {
          this.wallpaper = res.data.pop();
        }
      }).catch(err => console.log(err))
    },
    download() {
      const fileName = this.wallpaper?.image;
      window.open(fileName, 'Download')
    }
  },
  created() {
    this.getWallpaper();
  }
}
</script>
