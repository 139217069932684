<template>
  <v-container>
    <v-row>
      <v-col cols="10">
        <h1>Privacy Policy</h1>
        <p>Just so you know, we use your Personal Information ONLY for providing and improving the Site. By using the
          Site, you agree to the collection and use of information in accordance with this policy. We do not sell or
          share this information with anyone else. Unless otherwise defined in this Privacy Policy, terms used in this
          Privacy Policy have the same meanings as in our Terms and Conditions, accessible at <a
            href="www.GraceWayRadio.com">www.GraceWayRadio.com</a>.</p>

        <h2>Information Collection And Use</h2>
        <p>While using our Site, we may ask you to provide us with certain personally identifiable information that can
          be used to contact or identify you. Personally identifiable information may include, but is not limited to,
          your name, email address, postal address, and phone number ("Personal Information").</p>

        <h2>Feedback Collection</h2>
        <p><strong>Authenticated Users:</strong> We collect feedback such as likes, dislikes, and favorites of songs.
          This feedback is linked to your UUID (Unique User Identifier) and not directly to any personal information.
        </p>
        <p><strong>Non-Authenticated Users:</strong> Your feedback (favorites, likes, dislikes) is stored in your
          browser's localStorage and not sent to our servers.</p>

        <h2>Webhook Chat System</h2>
        <p>We have implemented a webhook chat system where users can send feedback, bug reports, or feature requests.
          Each conversation thread is stored with a unique identifier. When using this system, you choose your preferred
          contact name, and no other personal information is collected. By using the contact form, you agree to
          correspondence with our development team for the purpose of improving our services. These threads are stored
          in our database for development purposes.</p>

        <h2>Log Data</h2>
        <p>Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log
          Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address,
          browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time
          spent on those pages, and other statistics.</p>

        <h2>Cookies</h2>
        <p>Cookies are files with small amounts of data, which may include an anonymous unique identifier. Cookies are
          sent to your browser from a website and stored on your computer's hard drive. We use cookies:</p>
        <ul>
          <li>For user authentication.</li>
          <li>To set unique device IDs for anonymous users to enhance user experience.</li>
        </ul>
        <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if
          you do not accept cookies, you may not be able to use some portions of our Site.</p>

        <h2>Security</h2>
        <p>The security of your Personal Information is important to us, but remember that no method of transmission
          over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially
          acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

        <h2>Links To Other Sites</h2>
        <p>Our Site may contain links to other sites that are not operated by us. If you click on a third-party link,
          you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every
          site you visit. GraceWay Radio has no control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third-party sites or services.</p>

        <h2>Newsletter Signup</h2>
        <p>Information collected through our newsletter signup forms is handled by a third-party service. By clicking
          the "sign up" button, you consent to the gathering and use of your personal information for sending
          newsletters.</p>

        <h2>Purpose of Data Collection</h2>
        <p>The information we gather is used for improving user experience and for modifying/improving the playlists we
          stream as an online radio station.</p>

        <h2>Changes To This Privacy Policy</h2>
        <p>GraceWay Radio may update this Privacy Policy from time to time. We will notify you of any changes by posting
          the new Privacy Policy on the Site. You are advised to review this Privacy Policy periodically for any
          changes.</p>

        <h2>Contact Us</h2>
        <p>If you have any questions about this Privacy Policy, please <a href="mailto:admin@gracewayradio.com">contact us.</a></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  metaInfo() {
    return {
      title: "Privacy Policy",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "We do not sell or share this information with anyone else. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at www.GraceWayRadio.com."
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: "We do not sell or share this information with anyone else. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at www.GraceWayRadio.com."
        },
        { vmid: "og:title", property: "og:title", content: "GraceWay Privacy Policy" },
        { vmid: "robots", name: "robots", content: "index,follow" },
        {
          vmid: "og:url",
          property: "og:url",
          content: "https://gracewayradio.com/privacy-policy"
        }
      ]
    };
  },
}
</script>
