export default function createWebSocketPlugin(socket) {
  return (store) => {
    store.$socket = socket;
    socket.on("infos document replaced", (payload) => {
      const { currentTrack: newCurrent, queue: newQueue } = payload;
      const { songInfo: currentTrack, queue: currentQueue } = store.state;
      const lastQueueItemChanged = currentQueue[-1]?.trackid !== newQueue[-1]?.trackid;
      const currentTrackChanged = currentTrack?.trackid !== newCurrent?.trackid;
      if (lastQueueItemChanged) {
        store.dispatch("getSongInfo", payload);
      } else {
        setTimeout(
          () => {
            store.dispatch("getSongInfo", payload);
          },
          currentTrackChanged ? 14000 : 0
        );
      }
    });
    socket.on("connect", () => {
      const { state: { deviceId } } = store;

      if (deviceId) {
        socket.emit("registerDevice", deviceId);
      } else {
        console.log('socket not connected, cant emit deviceId')
      }
    });
    socket.on("slide", (data) => {
      console.log("slide");
      store.dispatch("slideObjects/getSlides");
    });
  };
}
