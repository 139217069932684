<script>
export default {
  name: 'DonateForm',
  data: () => ({
    url: "https://secure.qgiv.com/for/gracewayradio/embed/71290/"
  })
}

</script>

<template>
  <v-container fluid>
    <iframe :key="url" :src="url" frameborder="0" scrolling="no" class="my-auto mx-auto"
      title="Graceway Radio Donation Form"></iframe>
  </v-container>
</template>

<style scoped>
iframe {
  height: 1100px;
  width: 100%;
}
</style>
